namespace AAS {

    export class ToolTipManagerMobileView {

        // Working for multiple only when triggered by Event for one Scope AAS
        // when initialized by constructor only initialized for first scope-aas 
        public constructor() {
            if (!this.isMobileShop()) {
                return;
            }

            this.bindEvents();
            this.triggerInitToolTips();
        }

        private bindEvents(): void {
            window.shell.subscribeTo('AAS.updateFinished', (payload) => {
                this.triggerInitToolTips(payload.querySelector);
            }, 'AAS.update');
        }

        private isMobileShop(): boolean {
            return document.querySelector('html').classList.contains('mobileshop');
        }

        private triggerInitToolTips(selector = '.scope-aas'): void {
            const scopeAas = document.querySelector(selector) as HTMLElement;

            if (!scopeAas) {
                return;
            }
            
            this.initToolTips(scopeAas);
        }

        private initToolTips(scopeAas: HTMLElement): void {
            const toolTipContainersList: NodeListOf<Element> = scopeAas.querySelectorAll('.aas-tooltip-container');
            const toolTipContainers: Array<HTMLElement> = Array.prototype.slice.call(toolTipContainersList);
            const modal: HTMLElement = scopeAas.querySelector('.aas-modal') as HTMLElement;

            if (!modal) {
                return;
            }
            
            const closeButtons = modal.querySelectorAll('.aas-modal-close') as NodeListOf<HTMLElement>;
            closeButtons.forEach(b => b.onclick = () => { modal.style.display = 'none';});

            for (const toolTipContainer of toolTipContainers) {
                const infoIcons = toolTipContainer.querySelectorAll('svg');
                const toolTipContent = toolTipContainer.querySelector('.aas-tooltip-content');

                const infoIconArray: Array<HTMLElement> = Array.prototype.slice.call(infoIcons);

                for (const infoIcon of infoIconArray) {
                    infoIcon.onclick = () => {
                        const content = modal.querySelector('.aas-modal-content');
                        content.innerHTML = toolTipContent.innerHTML;
                        modal.style.display = 'block';
                    };
                }
            }

            window.onclick = (event) => {
                if (event.target === modal) {
                    modal.style.display = 'none';
                }
            };
        }
    }
}

new AAS.ToolTipManagerMobileView();
