namespace AAS {
    export class StickyHeader {
        private aasScope: HTMLElement;
        private aasSalesDesignationHeader: HTMLElement;
        private originalSalesDesignationOffSetTop: number;
        private didScroll: boolean;
        private aasContent: HTMLElement;
        private consumerCssModification = '';
        private isPDP = false;

        constructor(public readonly savKey: string, public readonly content: string, private shouldSticky: boolean = false) {
            const isMobileShop: boolean = document.getElementsByTagName('html')[0].classList.contains('mobileshop');
            this.isPDP = !!document.getElementById('product-details-page-entry');

            if (!isMobileShop) {
                return;
            }

            this.aasScope = document.querySelector(`.scope-aas[data-sav-key="${savKey}"][data-content="${content}"]`) as HTMLElement;

            this.aasSalesDesignationHeader = this.aasScope.querySelector('.aas-salesdesignation-header-container') as HTMLElement;
            const salesDesignation: HTMLElement = this.aasScope.querySelector('.aas-grid .index-product .sav-salesdesignation') as HTMLElement;
            this.originalSalesDesignationOffSetTop = salesDesignation.offsetTop;
            this.aasContent = this.aasScope.querySelector('.aas-content') as HTMLElement;
            this.bindEvent();

            if (shouldSticky) {
                this.shouldSticky = shouldSticky;
            }
        }

        private bindEvent() {
            setInterval(() => {
                if (this.didScroll && this.shouldSticky) {
                    this.didScroll = false;
                    this.setHeaderSticky();
                }
            }, 125);

            document.addEventListener('scroll', () => {
                this.didScroll = true;
            }, true);

            window.shell.subscribeTo('AAS.view.shown', (payload) => { this.rebindScrollContainer(payload); }, 'AAS.view.shown.sticky.header');
            window.shell.subscribeTo('AAS.view.changed', (payload) => { this.saveConsumerViewChange(payload); }, 'AAS.view.shown.sticky.header');
        }

        private rebindScrollContainer(payload: AASViewShownPayload) {
            if (payload.detail.useStickyHeader != null) {
                this.shouldSticky = payload.detail.useStickyHeader;
                if (this.isPDP) {
                    const salesDesignation: HTMLElement = this.aasScope.querySelector('.aas-grid .index-product .sav-salesdesignation') as HTMLElement;
                    this.originalSalesDesignationOffSetTop = salesDesignation.offsetTop;
                }
            }
        }

        private saveConsumerViewChange(payload: AASViewChangedPayload) {
            if (payload.detail.cssModification == null) {
                return;
            }

            if (payload.detail.cssModification.salesdesignationHeaderViewSticky == null) {
                return;
            }

            this.consumerCssModification = payload.detail.cssModification.salesdesignationHeaderViewSticky;
            this.setConsumerViewChange();
        }

        private setConsumerViewChange() {
            if (this.aasSalesDesignationHeader.classList.contains('sticky')) {
                this.aasSalesDesignationHeader.setAttribute('style', this.consumerCssModification);
            } else {
                this.aasSalesDesignationHeader.setAttribute('style', '');
            }
        }

        private setHeaderSticky(): void {
            const topPosition: number = this.originalSalesDesignationOffSetTop - this.scrollPosition();
            const shouldStick: boolean = topPosition <= 10;
            this.aasSalesDesignationHeader.classList.toggle('sticky', shouldStick);
            this.setConsumerViewChange();
        }

        private scrollPosition(): number {
            return this.aasContent != null ? this.aasContent.scrollTop : window.pageYOffset;
        }
    }
}

const scopeForStickyHeader = document.querySelectorAll('.scope-aas')[0] as HTMLElement;

if (scopeForStickyHeader && scopeForStickyHeader.querySelector('.aas-grid')) {
    const stickyHeader = new AAS.StickyHeader(scopeForStickyHeader.dataset.savKey, scopeForStickyHeader.dataset.content, true);
}
