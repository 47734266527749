namespace AAS {
    export class ActionTracker {

        static triggerOpenDetailsEvent() {
            eventAggregator.publish('actionControl.genericAction',
                { actionSubject: 'alternative', actionType: 'open' });
        }

        constructor() {
            const triggerActionEvent = () => {
                const aasScopeAvailable = document.querySelector('.scope-aas');
                if (aasScopeAvailable && typeof (eventAggregator) === 'object') {

                    // 
                    // delayed publish event in order to let action tracker first subscribe
                    //
                    setTimeout(() => {
                        eventAggregator.publish('actionControl.genericAction',
                            { actionSubject: 'alternative', actionType: 'loaded' });
                    }, 1000);
                }
            };

            if (document.readyState === 'complete') {
                triggerActionEvent();
            } else {
                document.addEventListener('DOMContentLoaded', triggerActionEvent);
            }
        }
    }
}

new AAS.ActionTracker();
