namespace AAS {
    export class Collapse {
        private rowHeight: { [index: string]: number } = {};
        private readonly separatorArray: Array<HTMLElement>;
        private readonly detailElements: Array<HTMLElement>;

        constructor(
            private readonly scope: HTMLElement,
            private subFeatureEqualHeightCalculator: SubFeaturesEqualHeight,
            public readonly savKey?: string
        ) {
            if (!scope) {
                return;
            }

            const separators: NodeListOf<Element> = this.scope.querySelectorAll('.separator');
            this.separatorArray = Array.prototype.slice.call(separators);
            if (!this.separatorArray.length) {
                return;
            }
            
            const details: NodeListOf<Element> = this.scope.querySelectorAll('.detailsHidden');
            this.detailElements = Array.prototype.slice.call(details);

            this.saveRowHeight();
            this.bindCollapseEvents();
            this.bindToggleOnlyDifferences();
            this.bindToggleShowDetails();

            this.adjustFallbackIconContainerHeight();
        }

        private saveRowHeight(): void {
            const collapseAble = this.scope.querySelectorAll('.collapse-able');
            const collapseAbleArray: Array<HTMLElement> = Array.prototype.slice.call(collapseAble);

            for (const row of collapseAbleArray) {
                const rowIndex: string = row.classList[0];
                const rowWrapperSelector = '.' + rowIndex + '.wrapper';
                const rowWrapper = this.scope.querySelector(rowWrapperSelector) as HTMLElement;

                row.style.height = 'auto';
                let height: number = row.clientHeight;
                if (rowWrapper) {
                    rowWrapper.style.removeProperty('height');
                    if (rowWrapper.clientHeight > height) {
                        height = rowWrapper.clientHeight;
                    }
                }
                
                this.rowHeight[rowIndex] = height;
                row.style.height = String(height) + 'px';
                if (rowWrapper) {
                    rowWrapper.style.height = String(height) + 'px';
                }
            }
        }

        private bindCollapseEvents(): void {
            this.bindToggleRow();
            this.bindToggleAllRows();
        }

        private bindToggleRow(): void {
            this.scope.addEventListener('click', (event: Event) => {
                const clickedElement: HTMLElement = event.target as HTMLElement;
                // ToDo: replace closest
                const clickedSeparator: HTMLElement | null = clickedElement.closest('.separator') as HTMLElement;
                if (clickedSeparator == null) {
                    return;
                }

                const shouldFold = !clickedSeparator.classList.contains('folded');
                this.setRowHeight(clickedSeparator, shouldFold);
            });
        }

        private bindToggleAllRows(): void {
            const collapseAllButton: HTMLElement = this.scope.querySelector('.collapse-all') as HTMLElement;

            collapseAllButton.addEventListener('click', () => {
                const shouldFold = !collapseAllButton.classList.contains('folded');
               
                for (const separator of this.separatorArray) {
                    this.setRowHeight(separator, shouldFold);
                }

                collapseAllButton.classList.toggle('folded');
            });
        }

        private bindToggleOnlyDifferences(): void {
            const button: HTMLElement = this.scope.querySelector('.only-differences') as HTMLElement;
           
            button.addEventListener('click', (event) => {
                this.toggleOnlyDifferences(event.currentTarget);
            });
        }

        private bindToggleShowDetails(): void {
            const button: HTMLElement = this.scope.querySelector('.collapse-details') as HTMLElement;

            if (!button) {
                return;
            }

            const collapseAllButton: HTMLElement = this.scope.querySelector('.collapse-all') as HTMLElement;
            const showOnlyDifferencesButton: HTMLElement = this.scope.querySelector('.only-differences') as HTMLElement;
            const subFeatures: NodeListOf<Element> = this.scope.querySelectorAll('.sub-features');
            const subFeaturesArray = Array.prototype.slice.call(subFeatures);
            
            button.addEventListener('click', (event) => {
                if (!button.classList.contains('folded')) {
                    ActionTracker.triggerOpenDetailsEvent();
                }

                button.classList.toggle('folded');

                for (const detailElement of this.detailElements) {
                    detailElement.classList.toggle('detailsHidden');
                    detailElement.classList.remove('hide-feature');
                    detailElement.style.removeProperty('height');
                }
            
                collapseAllButton.classList.remove('folded');
                for (const separator of this.separatorArray) {
                    separator.classList.remove('folded');
                }

                showOnlyDifferencesButton.classList.remove('checked');

                for (const subFeature of subFeaturesArray) {
                    subFeature.style.removeProperty('height');
                    subFeature.classList.remove('hide-feature');
                }

                this.subFeatureEqualHeightCalculator.setEqualHeightForEachSubFeature();

                this.saveRowHeight();
            });
        }

        private loadingSpinner(open: boolean) {
            return new Promise(resolve => {
                if (open) {
                    this.scope.classList.add('open-overlay');
                } else {
                    this.scope.classList.remove('open-overlay');
                }
                setTimeout(() => {
                    resolve('true');
                }, 1000);
            });
        }

        async toggleOnlyDifferences(button: EventTarget) {
            const clickedButton = button as HTMLElement;
            clickedButton.classList.toggle('checked');

            const showOnlyDifferences: boolean = clickedButton.classList.contains('checked');

            await this.loadingSpinner(true);
            this.toggleSameFeatures(showOnlyDifferences);
            this.updateSameSubFeatures();
            this.loadingSpinner(false);
        }

        private toggleSameFeatures(showOnlyDifferences: boolean): void {
            const sameFeatures = this.scope.querySelectorAll('.same-features');
            const sameFeaturesArray: Array<HTMLElement> = Array.prototype.slice.call(sameFeatures);

            for (const sameFeature of sameFeaturesArray) {
                if (showOnlyDifferences) {
                    sameFeature.classList.add('hide-feature');
                    if (sameFeature.classList.contains('extras')) {
                        sameFeature.parentElement.classList.add('hide-feature')
                    }
                } else {
                    sameFeature.classList.remove('hide-feature');
                    if (sameFeature.classList.contains('extras')) {
                        sameFeature.parentElement.classList.remove('hide-feature')
                    }
                }
            }
        }

        private updateSameSubFeatures(): void {
            const featureWithSameSubFeatures = this.scope.querySelectorAll('.same-sub-features');
            const featureWithSameSubFeaturesArray: Array<HTMLElement> = Array.prototype.slice.call(featureWithSameSubFeatures);

            for (const feature of featureWithSameSubFeaturesArray) {
                const index: string = feature.classList[0];
                const rowWithSubFeatures: NodeListOf<Element> = this.scope.querySelectorAll('.' + index + ':not(.separator)');
                const rowWithSubFeaturesArray: Array<HTMLElement> = Array.prototype.slice.call(rowWithSubFeatures);
                const featureRowIsFolded: boolean = feature.classList.contains('folded');

                for (const row of rowWithSubFeaturesArray) {
                    this.adjustSubFeatureStyles(row);
                    this.updateRowHeight(row, index, featureRowIsFolded);
                }

                if (!featureRowIsFolded) {
                    this.setRowHeight(feature, false);
                }
            }
        }

        private adjustSubFeatureStyles(row: HTMLElement): void {
            const subFeatureContainers: NodeListOf<Element> = row.querySelectorAll('.sav-filters');
            const subFeatureContainersArray: Array<HTMLElement> = Array.prototype.slice.call(subFeatureContainers);

            for (const container of subFeatureContainersArray) {
                this.adjustLastSubFeatureDividerVisibility(container);
            }
        }

        private adjustLastSubFeatureDividerVisibility(container: HTMLElement): void {
            const lastVisibleBeforeUpdate: HTMLElement = container.querySelector('.sub-features.last-visible') as HTMLElement;
            if (lastVisibleBeforeUpdate) {
                lastVisibleBeforeUpdate.classList.remove('last-visible');
            }

            const visibleNow: NodeListOf<Element> = container.querySelectorAll('.sub-features:not(.hide-feature)');
            const lastVisibleNow: HTMLElement = visibleNow[visibleNow.length - 1] as HTMLElement;
            if (lastVisibleNow) {
                lastVisibleNow.classList.add('last-visible');
            }
        }

        // TODO: Clarify the terminology. As the input row is a HTMLElement, for me it more like a cell
        private updateRowHeight(row: HTMLElement, index: string, isFolded: boolean): void {
            row.style.height = 'auto';
            this.rowHeight[index] = row.clientHeight;

            if (isFolded) {
                row.style.height = '0';
            }
        }

        private setRowHeight(separator: HTMLElement, fold: boolean): void {
            // ToDo: make it save! Can break when someone is changing order of classes in cshtml
            const rowIndex: string = separator.classList[0];
            const rowElements: NodeListOf<Element> = this.scope.querySelectorAll('.' + rowIndex + ':not(.separator)');
            const rowElementsArray: Array<HTMLElement> = Array.prototype.slice.call(rowElements);

            const rowHeight: number = fold ? 0 : this.rowHeight[rowIndex];

            for (const element of rowElementsArray) {
                element.style.height = String(rowHeight) + 'px';
            }

            if (fold) {
                separator.classList.add('folded');
            } else {
                separator.classList.remove('folded');
            }
        }

        private adjustFallbackIconContainerHeight() {
            const savFilterValues = this.scope.querySelectorAll('.sav-filters');
            const featureValueRowToAdjust: string[] = [];
            
            //
            // Determine actual height of feature values having height >= 48px (more than 2 lines)
            // Only for that specific feature value rows we need to adjust height of fallback icons of that feature value row
            //
            savFilterValues.forEach((savFilterValue) => {
                savFilterValue.querySelectorAll('.aas-feature-value-distance[data-feature-value-row]').forEach((savFilterFeatureValue: HTMLElement) => {
                    if (savFilterFeatureValue.clientHeight >= 48) {
                        const featureRow = savFilterFeatureValue.dataset.featureValueRow;
                        const featureIndex = savFilterFeatureValue.dataset.featureIndex;
                        const featureRowIdentifier = featureIndex + '#' + featureRow + '#' + savFilterFeatureValue.clientHeight;
                        if (featureValueRowToAdjust.indexOf(featureRowIdentifier) < 0) {
                            featureValueRowToAdjust.push(featureRowIdentifier);
                        }
                    }
                });
            });

            //
            // Adjust fallback icons height of feature value rows where needed
            //
            featureValueRowToAdjust.forEach(featureRowIdentifier => {
                const featureRowIdentifierParts = featureRowIdentifier.split('#');
                savFilterValues.forEach((savFilterValue) => {
                    savFilterValue.querySelectorAll(`.aas-feature-value-fallback-icon[data-feature-index='${featureRowIdentifierParts[0]}'][data-feature-value-row='${featureRowIdentifierParts[1]}']`).forEach((fallbackIcon : HTMLElement) => {
                        fallbackIcon.style.height = featureRowIdentifierParts[2] + 'px';
                    });
                });
            });
        }
    }
}
