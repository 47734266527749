namespace AAS {

    export class ToolTipScroll {
        private scope: HTMLElement;
        private readonly closeDelay: number = 2000;

        public constructor() {
            if (this.isMobileShop()) {
                return;
            }

            this.bindEvents();
        }

        private bindEvents(): void {
            window.shell.subscribeTo('AAS.updateFinished', (payload) => {
                this.triggerInitToolTips(payload.querySelector);
            }, 'AAS.update');
        }

        private triggerInitToolTips(selector = '.scope-aas.aas-full'): void {
            const scopeAas = document.querySelector(selector) as HTMLElement;

            if (!scopeAas) {
                return;
            }

            this.scope = scopeAas;
            this.registerEvents();
        }

        private get supportsNativeSmoothScroll() {
            return 'scrollBehavior' in document.documentElement.style;
        }

        private isMobileShop(): boolean {
            return document.querySelector('html').classList.contains('mobileshop');
        }

        private registerEvents(): void {
            const aasToolTipContainer = this.scope.querySelectorAll('.aas-tooltip-container');
            const aasToolTipContainerArray: Array<HTMLElement> = Array.prototype.slice.call(aasToolTipContainer);

            for (const aasTooltipContainer of aasToolTipContainerArray) {
                const aasTooltip = aasTooltipContainer.querySelector('.aas-tooltip');

                aasTooltipContainer.addEventListener('mouseenter', () => {
                    this.handleMouseOverTooltipEvent(aasTooltip);
                });
                aasTooltipContainer.addEventListener('mouseleave', () => {
                    this.handleMouseLeaveTooltipEvent(aasTooltip);
                });
            }
        }

        private handleMouseOverTooltipEvent(aasToolTip: Element): void {
            this.closeOpenTooltips();
            aasToolTip.classList.add('show-aas-tooltip');
            this.scrollTooltipIntoViewportIfNeeded(aasToolTip);
        }

        private closeOpenTooltips() {
            const allTooltips = this.scope.querySelectorAll('.aas-tooltip-container .aas-tooltip.show-aas-tooltip');
            const allTooltipsArray: Array<HTMLElement> = Array.prototype.slice.call(allTooltips);
            for (const tooltip of allTooltipsArray) {
                tooltip.classList.remove('show-aas-tooltip');
            }
        }

        private scrollTooltipIntoViewportIfNeeded(aasToolTip: Element): void {
            const ttTop: number = aasToolTip.getBoundingClientRect().top;
            const currentToolTipHeight: number = aasToolTip.clientHeight + ttTop;

            if (this.shouldScrollUp(ttTop) || this.shouldScrollDown(currentToolTipHeight)) {
                aasToolTip.classList.add('scrollingToViewport');
                let positionToScrollTo: number =
                    this.shouldScrollUp(ttTop) ? ttTop - 20 : currentToolTipHeight - window.innerHeight + 20;

                if (this.supportsNativeSmoothScroll) {
                    window.scrollBy({
                        'behavior': 'smooth',
                        'top': positionToScrollTo
                    });
                } else {
                    // Only for IE 11
                    positionToScrollTo = (this.shouldScrollUp(ttTop) ? positionToScrollTo * -1 : positionToScrollTo);
                    for (let index = 1; index <= positionToScrollTo; index++) {
                        this.smoothScrollBy(this.shouldScrollUp(ttTop) ? -1 : 1);
                    }
                }

                setTimeout(() => {
                    aasToolTip.classList.remove('scrollingToViewport');
                }, this.closeDelay);
            }
        }

        private shouldScrollUp(top: number): boolean {
            return top < 0;
        }

        private shouldScrollDown(currentToolTipHeight: number): boolean {
            return currentToolTipHeight > window.innerHeight;
        }

        private handleMouseLeaveTooltipEvent(aasToolTip: Element): void {
            if (aasToolTip.classList.contains('scrollingToViewport')) {
                setTimeout(() => { aasToolTip.classList.remove('show-aas-tooltip'); }, this.closeDelay);
                aasToolTip.classList.remove('scrollingToViewport');
            } else {
                aasToolTip.classList.remove('show-aas-tooltip');
            }
        }

        private smoothScrollBy(index: number): void {
            setTimeout(() => { window.scrollBy(0, index); }, 300);
        }
    }
}

new AAS.ToolTipScroll();
