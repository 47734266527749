namespace AAS {
    export class SubFeaturesEqualHeight {
        private scope: HTMLElement;
        private subFeatureImageArray: Array<HTMLImageElement>;
        private selectedSubFeatureArray: Array<HTMLElement>;
        private imageLoadingCounter = 0;
        private textModulesTextOnly: NodeListOf<Element>;


        public constructor(scope: HTMLElement) {
            if (!scope) {
                return;
            }

            this.scope = scope;
            const selectedSubFeatures: NodeListOf<Element> = this.scope.querySelectorAll('.selected.has-sub-features');
            this.selectedSubFeatureArray = Array.prototype.slice.call(selectedSubFeatures);
            const subFeatureImages: NodeListOf<Element> = this.scope.querySelectorAll('.sub-features img.feature-image');

            this.textModulesTextOnly = this.scope.querySelectorAll('.sub-features.extras div.feature-textbaustein-noimage');

            this.subFeatureImageArray = Array.prototype.slice.call(subFeatureImages);

            this.setImageLoadingMonitor();
        }
        
        private setImageLoadingMonitor() {
            if (!this.subFeatureImageArray.length) {

                // we still need to recalculate heights of subfeature cells
                // in case only textmodules with text (no images) are present
                if (this.textModulesTextOnly.length > 0) {
                    this.setEqualHeightForEachSubFeature();
                }

                new Collapse(this.scope, this);
                return;
            }

            // if subfeature images wait until all are loaded
            // then recalculate heights of subfeature cells
            this.imageLoadingCounter = 0;
            for (const image of this.subFeatureImageArray) {
                if (image.complete) {
                    this.imageLoadFinished.bind(this)();
                } else {
                    image.addEventListener('load', this.imageLoadFinished.bind(this));
                    // we don't care if image load failed
                    image.addEventListener('error', this.imageLoadFinished.bind(this));
                }
            }
        }

        private imageLoadFinished() {
            this.imageLoadingCounter += 1;
            if (this.imageLoadingCounter === this.subFeatureImageArray.length) {
                this.setEqualHeightForEachSubFeature();
                new Collapse(this.scope, this);
            }
        }

        public setEqualHeightForEachSubFeature() {
            for (const rowSelected of this.selectedSubFeatureArray) {
                // ToDo: make it save! Can break when someone is changing order of classes in cshtml
                const rowIndex = rowSelected.classList[0];
                const rowWrapper = '.' + rowIndex + '.wrapper';
                const rowWrapperElement = this.scope.querySelector(rowWrapper);

                const subFeatureSelectedRow = rowSelected.querySelectorAll('.sub-features');
                const subFeatureWrapperRow = rowWrapperElement.querySelectorAll('.sub-features');
                const subFeatureRowArray: Array<HTMLElement> = Array.prototype.slice.call(subFeatureSelectedRow);
                const subFeatureRowArray2: Array<HTMLElement> = Array.prototype.slice.call(subFeatureWrapperRow);

                for (const subFeatureRow of subFeatureRowArray) {
                    // ToDo: make it save! Can break when someone is changing order of classes in cshtml
                    const subFeatureIndex = subFeatureRow.classList[1];
                    const subFeatures = subFeatureRowArray2.filter(sf => sf.classList.contains(subFeatureIndex));
                    subFeatures.unshift(subFeatureRow);

                    const maxOfHeight = this.getMaxOfHeight(subFeatures);
                    this.setSubFeatureHeight(subFeatures, maxOfHeight);
                }
            }
        }

        private getMaxOfHeight(subFeatures: HTMLElement[]): number {
            const subFeatureHeightArray: Array<number> = [];
            for (const subFeature of subFeatures) {
                subFeatureHeightArray.push(subFeature.clientHeight);
            }

            return Math.max(...subFeatureHeightArray);
        }

        private setSubFeatureHeight(subFeatures: HTMLElement[], height: number): void {
            for (const subFeature of subFeatures) {
                subFeature.style.height = height + 'px';
            }
        }
    }
}

//ToDo: find a better way to init this
//ToDo: should consider multiple instance problem
//ToDo: should be identifiable via savKey or something
// For now, just init for first instance of aas scope
// Currently only needed for standalone mode
document.addEventListener('DOMContentLoaded',
    function () {
        const scope = document.querySelector('.scope-aas.aas-full') as HTMLElement;
        new AAS.SubFeaturesEqualHeight(scope);
    }
);
