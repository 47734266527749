/// <reference path='../typings/hammerjs.d.ts'/>
namespace AAS {
    export class Carousels {
        private cardsCount: number;
        private currentIndex: number;
        constructor(private readonly aasScope: HTMLElement, public readonly savKey?: string) {
            const isMobileShop: boolean = document.getElementsByTagName('html')[0].classList.contains('mobileshop');
            if (!isMobileShop) {
                return;
            }
            this.initCarousel(aasScope);
            this.bindEvents(aasScope);
            this.updatePager();
        }

        private initCarousel(scope: HTMLElement): void {
            // always start from beginning
            this.currentIndex = 0;
            const currentIndexSelector = this.getSelectorFromCardIndex(this.currentIndex);

            //assign init position for all cards and wrappers
            const wrappers = scope.querySelectorAll('.wrapper');
            const wrappersArray: Array<HTMLElement> = Array.prototype.slice.call(wrappers);
            for (const element of wrappersArray) {
                element.classList.add(currentIndexSelector);
            }
            const card = scope.querySelector('.aas-card');
            card.classList.add(currentIndexSelector);

            //get total count of cards from pager
            const dotWrapper = scope.querySelector('.pager-dot-wrapper');
            const dots = dotWrapper.querySelectorAll('.pager-dot');
            this.cardsCount = dots.length;
        }

        private bindEvents(scope: HTMLElement): void {
            this.bindNext(scope);
            this.bindPrev(scope);
            const aasContent: HTMLElement = scope.querySelector('.aas-content') as HTMLElement;
            const hammertime = new Hammer.Manager(aasContent, {
                domEvents: true,
                recognizers: [
                    [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 5, velocity: 0.1 }]
                ],
                drag_block_vertical: true
            });

            hammertime.on('swipe', (event: HammerInput) => {
                const gestureAngle: number = Math.abs(event.angle);
                if (gestureAngle >= 0 && gestureAngle <= 45) {
                    //swipe to right, within a cone of +/-45deg
                    this.goPrev();
                } else if (gestureAngle >= 135 && gestureAngle <= 180) {
                    //swipe to left, within a cone of +/-45deg
                    this.goNext();
                }
            });
        }

        private getSelectorFromCardIndex(index: number): string {
            return 'card-' + index.toString();
        }

        private bindNext(scope: HTMLElement): void {
            const nextButton: Element = scope.querySelector('.pager-arrow-right');
            if (nextButton) {
                nextButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.goNext();
                });
            }
        }

        private goNext() {
            const nextIndex = (this.currentIndex + 1) < this.cardsCount ? this.currentIndex + 1 : this.currentIndex;
            this.updateIndexSelector(nextIndex);
        }

        private bindPrev(scope: HTMLElement): void {
            const prevButton: Element = scope.querySelector('.pager-arrow-left');
            if (prevButton) {
                prevButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.goPrev();
                });
            }
        }

        private goPrev() {
            const prevIndex = (this.currentIndex - 1) >= 0 ? this.currentIndex - 1 : this.currentIndex;
            this.updateIndexSelector(prevIndex);
        }

        private updateIndexSelector(newIndex: number) {
            if (newIndex === this.currentIndex) {
                return;
            }

            const currentIndexSelector: string = this.getSelectorFromCardIndex(this.currentIndex);
            const newIndexSelector: string = this.getSelectorFromCardIndex(newIndex);
            const wrappers = this.aasScope.querySelectorAll('.wrapper');
            const wrappersArray: Array<Element> = Array.prototype.slice.call(wrappers);
            for (const element of wrappersArray) {
                element.classList.add(newIndexSelector);
                element.classList.remove(currentIndexSelector);
            }

            const card = this.aasScope.querySelector('.aas-card');
            card.classList.remove(currentIndexSelector);
            card.classList.add(newIndexSelector);

            this.currentIndex = newIndex;

            //Also update pager
            this.updatePager();
        }

        private updatePager() {
            const pagerWrappers: NodeListOf<Element> = this.aasScope.querySelectorAll('.pager-dot-wrapper');
            const pagerWrapperArray: Array<Element> = Array.prototype.slice.call(pagerWrappers);
            for (const element of pagerWrapperArray) {
                const pagerDots = element.querySelectorAll('.pager-dot');
                const pagerDotsArray: Array<HTMLElement> = Array.prototype.slice.call(pagerDots);
                for (const dot of pagerDotsArray) {
                    dot.classList.remove('current-dot');
                }
                pagerDotsArray[this.currentIndex].classList.add('current-dot');
            }
           
            const prevButton: HTMLElement = this.aasScope.querySelector('.pager-arrow-left') as HTMLElement;
            const nextButton: HTMLElement = this.aasScope.querySelector('.pager-arrow-right') as HTMLElement;
            prevButton.classList.remove('hidden');
            nextButton.classList.remove('hidden');
            if (this.currentIndex === 0) {
                prevButton.classList.add('hidden');
            } else if (this.currentIndex === (this.cardsCount - 1)) {
                nextButton.classList.add('hidden');
            }
        }
    }
}

//ToDo: find a better way to init this
//ToDo: should consider multiple instance problem
//ToDo: should be identifiable via savKey or something
// For now, just init for first instance of aas scope
const scopeForCarousel = document.querySelectorAll('.scope-aas')[0] as HTMLElement;

if (scopeForCarousel && scopeForCarousel.querySelector('.aas-grid')) {
    const carousels = new AAS.Carousels(scopeForCarousel);
}

