/// <reference path='../typings/ajax-loader.d.ts'/>

namespace AAS {
    export class AjaxLoaderRequest implements IAjaxLoaderRequest {

        constructor(
            readonly apiUrl: string,
            readonly isMobileShop: boolean,
        ) {
        }

        requestFor(savKeyToLoad: string, content: string, itemOrigin: string, detailsCanBeHidden: boolean, displayedInCategoryNavKey: string, showHeader: boolean): Promise<string> {
            return new Promise<string>(resolve => {
                const url = this.getAjaxUrl(savKeyToLoad, content, itemOrigin, detailsCanBeHidden, displayedInCategoryNavKey, showHeader);
                const request = new XMLHttpRequest();

                request.onreadystatechange = () => {
                    if (request.readyState === 4 && request.status === 200) {
                        resolve(request.responseText);
                    } else if (request.status >= 300 || request.status === 204) {
                        resolve('');
                    }
                };

                request.ontimeout = () => {
                    resolve('');
                };
                
                request.open('GET', url, true);
                request.timeout = 30000;
                request.send();
            });
        }

        private getAjaxUrl(savKey: string, content: string, itemOrigin: string, detailsCanBeHidden: boolean, displayedInCategoryNavKey: string, showHeader: boolean): string {
            return `${this.apiUrl}articles/${savKey}` 
                + '?viewMode=ajax'
                + '&maxNumberOfAlternativeArticles=3'
                + `&isMobileShop=${this.isMobileShop}` 
                + `&content=${content}` 
                + (itemOrigin != null ? `&itemorigin=${itemOrigin}` : '')
                + `&detailsCanBeHidden=${detailsCanBeHidden}` 
                + (displayedInCategoryNavKey ? `&displayedInCategoryNavKey=${displayedInCategoryNavKey}` : '')
                + `&showHeader=${showHeader}`;
        }
    }
}

